
import Vue from 'vue';
import Options from 'vue-class-component';
@Options({
    components: {},
})
/** A dial for the position of the inflection point of the gradation
 * @remarks Uses the vuex store to retrieve and update the inflection point value.
 */
export default class GradationDial extends Vue {
    get gradationInflectionPoint(): number {
        return this.$store.getters.gradationInflectionPoint;
    }
    set gradationInflectionPoint(value: number) {
        this.$store.dispatch('updateGradationInflectionPoint', value);
    }
}


import { Component, Vue } from 'vue-property-decorator';
import AboutText from '@/components/AboutText.vue';
@Component({
    components: { AboutText },
})
/** A simple splash screen with a short introduction
 */
export default class Splash extends Vue {
    showDialog = false;

    mounted() {
        this.showDialog = !this.neverShowSplashAgain === true;
    }

    get neverShowSplashAgain(): boolean {
        return this.$store.getters.neverShowSplashAgain;
    }
    set neverShowSplashAgain(value: boolean) {
        this.$store.dispatch('updateNeverShowSplashAgain', value);
    }
}

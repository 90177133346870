
import { Component, Vue } from 'vue-property-decorator';
import SkySelector from '@/components/SkySelector.vue'; // @ is an alias to /src
import LampSelector from '@/components/LampSelector.vue'; // @ is an alias to /src
import LightEmittingArea from '@/components/LightEmittingArea.vue'; // @ is an alias to /src
import GradationDial from '@/components/GradationDial.vue'; // @ is an alias to /src
import Splash from '@/components/Splash.vue'; // @ is an alias to /src
import { AnnotatedColor } from '@/code/AnnotatedColor';
import NoSleep from 'nosleep.js';

@Component({
    components: {
        GradationDial,
        SkySelector,
        LampSelector,
        LightEmittingArea,
        Splash,
    },
})
export default class Office extends Vue {
    get selectedLampType(): AnnotatedColor {
        return this.$store.getters.selectedLampType;
    }

    get selectedSkyType(): AnnotatedColor {
        return this.$store.getters.selectedSkyType;
    }

    noSleep = new NoSleep();

    mounted(): void {
        this.noSleep.enable();
    }
    destroyed(): void {
        this.noSleep.disable();
    }
}

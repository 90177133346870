
import { Component, Vue } from 'vue-property-decorator';
import { AnnotatedColor } from '@/code/AnnotatedColor';

@Component({
    components: {},
})
/** An area that emits the light according to the selected lighting parameters
 * @remarks Uses the Sky and Lamp type, plus the inflection from the vuex store.
 */
export default class LightEmittingArea extends Vue {
    /** Returns the lower boundary of the inflection area */
    get lowerInflectionBoundary(): number {
        var lowerSplitPosition = Math.max(
            0,
            Number(this.gradationInflectionPoint) -
                Number(this.gradationInflectionWidth)
        );
        //console.debug('lowerSplitPosition', lowerSplitPosition);
        return lowerSplitPosition;
    }
    /** Returns the upper boundary of the inflection area */
    get upperInflectionBoundary(): number {
        var upperSplitPosition = Math.min(
            100,
            Number(this.gradationInflectionPoint) +
                Number(this.gradationInflectionWidth)
        );
        //console.debug('upperSplitPosition', upperSplitPosition);
        return upperSplitPosition;
    }

    get selectedLampType(): AnnotatedColor {
        return this.$store.getters.selectedLampType;
    }

    get selectedSkyType(): AnnotatedColor {
        return this.$store.getters.selectedSkyType;
    }

    get gradationInflectionPoint(): number {
        return this.$store.getters.gradationInflectionPoint;
    }

    get gradationInflectionWidth(): number {
        return this.$store.getters.gradationInflectionWidth;
    }
}

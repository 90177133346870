var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"light-emitting-area",style:({
        background: `linear-gradient(90deg, 
        ${_vm.selectedSkyType.color.toString()} 
        ${_vm.lowerInflectionBoundary}%, 
        ${_vm.selectedLampType.color.toString()} 
        ${_vm.upperInflectionBoundary}%
        )`,
    })})
}
var staticRenderFns = []

export { render, staticRenderFns }
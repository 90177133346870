
import Vue from 'vue';
import Options from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { AnnotatedColor } from '../code/AnnotatedColor';
@Options({
    components: {},
})
/** A speed dial for a set of annotated colors
 * @remarks Takes no preset, but just emits any selected color as the "change" event
 */
export default class ColorDial extends Vue {
    /** The name of the material icon to use for the target button */
    @Prop({ required: false, type: String, default: 'help' })
    targetButtonName!: string;

    /** The set of colors to choose from */
    @Prop({ required: true, type: Array })
    annotatedColors!: AnnotatedColor[];

    /** The CSS class to use for the dial */
    @Prop({ required: false, type: String })
    dialClass!: string;

    /** The direction of the options
     * @remarks Must be "top" or "bottom", default is "bottom"
     */
    @Prop({ required: false, type: String, default: 'bottom' })
    direction!: string;

    selectedLightType = 'highNoonSun';

    @Watch('selectedLightType')
    lightTypeChanged(newValue: string): void {
        console.debug('ColorDial.vue::lightTypeChanged->newValue', newValue);
        for (var annotatedColor of this.annotatedColors) {
            if (annotatedColor.id === newValue) {
                this.emitColorChange(annotatedColor);
            }
        }
    }
    emitColorChange(color: AnnotatedColor): void {
        console.debug('ColorDial.vue::emitColorChange->emit', color);
        this.$emit('changed', color);
    }
}
